<template>
  <div class="login-container">
    <div class="shapes-container">
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="1500"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-up-right"
        data-aos-duration="1000"
        data-aos-delay="200"
      ></div>
      <div
        class="shape"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="1000"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="1000"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-delay="300"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-right"
        data-aos-duration="500"
        data-aos-delay="200"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-right"
        data-aos-duration="500"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="zoom-out"
        data-aos-duration="2000"
        data-aos-delay="500"
      ></div>
      <div
        class="shape"
        data-aos="fade-up-right"
        data-aos-duration="500"
        data-aos-delay="200"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="500"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-delay="0"
      ></div>
      <div
        class="shape"
        data-aos="fade-down"
        data-aos-duration="500"
        data-aos-delay="0"
      ></div>
      <div
        class="shape"
        data-aos="fade-up-right"
        data-aos-duration="500"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="500"
        data-aos-delay="0"
      ></div>
    </div>
    <section>
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="block login-marco">
              <div class="image align-self-center login-a">
                <b-img
                  :src="
                    require('@/assets/img/background/tbolivar-edeficio.jpg')
                  "
                  alt="login-img"
                  class="login-img"
                ></b-img>
              </div>
              <div class="content text-center login-background">
                <div class="content text-center">
                  <div class="logo">
                    <b-link href="/">
                      <b-img
                        :src="require('@/assets/img/logos/logo-01.png')"
                        alt="logo-tributos"
                      ></b-img>
                    </b-link>
                  </div>
                  <br />
                  <div class="title-text">
                    <h3>Recuperar Clave</h3>
                  </div>
                  <br />
                  <br />
                  <b-form @submit.prevent="forgotPassword">
                    <b-form-input
                      type="email"
                      placeholder="Correo"
                      required
                      v-model="email"
                      autocomplete="email"
                      name="email"
                    ></b-form-input>
                    <br />
                    <br />
                    <b-button type="submit" block variant="primary">
                      Enviar
                    </b-button>
                  </b-form>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: null,
    };
  },
  methods: {
    async forgotPassword() {
      try {
        const payload = {
          email: this.email,
        };
        await AuthService.forgotPassword(payload);
        this.$toast.success(
          "Un mensaje de recuperación ha sido enviado a su email.",
          { timeout: 3000 }
        );
      } catch (error) {
        this.$toast.error(getError(error), { timeout: 3000 });
      }
    },
  },
};
</script>
